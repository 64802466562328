<template>
  <div id="contactus">
    <section class="contact-wrap style2 pb-100 mt-5">
      <!--<div class="map-bg bg-f"></div>-->
      <div class="container">
        <div class="section-title style1 text-center mb-40">
          <span class="text-sunshade">
            <img :src="sectionshape1" alt="Image" />
            Contact Us
            <img :src="sectionshape2" alt="Image" />
          </span>
          <h2>
            Not Sure What To Order? <br />
            Download Our App
            <div class="download-app-btn">
              <a href="#"><img :src="googleplaystore" alt="Image" /></a>
              <a href="#"><img :src="appleplaystore" alt="Image" /></a>
            </div>
          </h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="contact-card">
              <div class="contact-icon">
                <i class="flaticon-home"></i>
              </div>
              <div class="contact-info">
                <h4>Our Location</h4>
                <p>76-55 CASTLERIDGE BLVD NE Calgary Alberta</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="contact-card">
              <div class="contact-icon">
                <i class="flaticon-mail"></i>
              </div>
              <div class="contact-info">
                <h4>Our Email</h4>
                <a href="mailto:fatimakarahi@gmail.com"
                  ><span
                    class="__cf_email__"
                    data-cfemail="3e565b5252517e5d5f5c5f50105d5153"
                    >fatimakarahi@gmail.com</span
                  ></a
                >
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="contact-card">
              <div class="contact-icon">
                <i class="flaticon-phone-call"></i>
              </div>
              <div class="contact-info">
                <h4>Contact Us</h4>
                <a href="tel:4034522575">403-452-2575</a>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="contact-card">
              <div class="contact-icon">
                <i class="flaticon-wall-clock-1"></i>
              </div>
              <div class="contact-info">
                <h4>Opening Hour</h4>
                <p>Mon - Sunday</p>
                <p>11:30 am - 11:00 pm</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-30 text-center">
            <!--<a href="contact.html" class="btn style1"
              >Explore Map <i class="flaticon-right-arrow-2"></i>
            </a>-->
            <router-link to="/contact" class="btn style1">
              Contact Us <i class="flaticon-right-arrow-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "contactus",
  data() {
    return {
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      googleplaystore: require("@/assets/img/app/google-playstore.png"),
      appleplaystore: require("@/assets/img/app/apple-playstore.png"),
    };
  },
};
</script>
