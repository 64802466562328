import axios from "axios";
import { apiHeaders } from "./service";

const apiUrl = "https://admin.fatimakarahicorner.com/public/api/";
const storeSlug = "fatima-o3yg7jxqy6djlpw";
// const storeID = "2";

export const getMenu = () => {
  return axios.post(`${apiUrl}get-restaurant-items/${storeSlug}`);
};

export const register = (data) => {
  return axios.post(`${apiUrl}register`, data);
};

export const verifyOtp = (data) => {
  return axios.post(`${apiUrl}verify-otp`, data);
};

export const login = (data) => {
  return axios.post(`${apiUrl}login`, data);
};
export const otp = (data) => {
  return axios.post(`${apiUrl}otp`, data);
};
export const otpverify = (data) => {
  return axios.post(`${apiUrl}otpverify`, data);
};

export const resendVerifyOtp = (data) => {
  return axios.post(`${apiUrl}resend_verify_otp`, data);
};

export const getGoogleApi = async () => {
  return await axios.get(`${apiUrl}get-setting/googleApiKey`).then((res) => {
    return new Promise((resolve) => {
      resolve(res.data);
    });
  });
};
export const saveAddress = (data) => {
  return axios.post(`${apiUrl}save-address`, data, { headers: apiHeaders() });
};

export const setDefaultAddress = (data) => {
  return axios.post(`${apiUrl}set-default-address`, data, {
    headers: apiHeaders(),
  });
};

export const getAddresses = (data) => {
  return axios.post(`${apiUrl}get-addresses`, data, { headers: apiHeaders() });
};

export const getUpdateInfo = () => {
  var data = "";
  return axios.post(`${apiUrl}update-user-info`, data, {
    headers: apiHeaders(),
  });
};

export const deleteAddress = (data) => {
  return axios.post(`${apiUrl}delete-address`, data, { headers: apiHeaders() });
};

export const getOrders = (data, role) => {
  return axios.post(`${apiUrl}get-orders?table=${role}`, data, {
    headers: apiHeaders(),
  });
};

export const getSettings = (data) => {
  return axios.post(`${apiUrl}get-settings`, data);
};

export const getRestaurantInfo = () => {
  return axios.post(`${apiUrl}get-restaurant-info/${storeSlug}`);
};

export const placeOrder = (data) => {
  return axios.post(`${apiUrl}place-order`, data, { headers: apiHeaders() });
};

export const getOrderDetail = (data) => {
  return axios.post(`${apiUrl}get-order-detail`, data, {
    headers: apiHeaders(),
  });
};

export const getSingleItem = (data) => {
  return axios.post(`${apiUrl}get-single-item`, data);
};

export const checkCoupon = (data) => {
  var datanew = {
    coupon: data.coupon,
    restaurant_id: data.restaurant_id,
    subtotal: data.subTotal,
  };
  return axios.post(`${apiUrl}apply-coupon`, datanew, {
    headers: apiHeaders(),
  });
};

export const sendPasswordResetMail = (data) => {
  return axios.post(`${apiUrl}send-password-reset-mail`, data);
};

export const changeUserPassword = (data) => {
  return axios.post(`${apiUrl}change-user-password`, data);
};

export const getUserWallet = () => {
  var data = "";
  return axios.post(`${apiUrl}get-wallet-transactions`, data, {
    headers: apiHeaders(),
  });
};

export const CardToken = (data) => {
  return axios.post(
    "https://api.clover.com/invoicingcheckoutservice/v1/checkouts",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer cddd8753-b7ac-b485-a292-1cb0a19e7e0c",
        "X-Clover-Merchant-ID": "13RPJBGYJ7331",
      },
    }
  );
};

export const tableLogin = (table) => {
  return axios.post(`${apiUrl}tablelogin/${table}`);
};

export const contactUs = (data) => {
  return axios.post(`${apiUrl}contact-us`, data);
};

export const cloverApiPayment = (data) => {
  return axios.post(`${apiUrl}card-payment`, data, { headers: apiHeaders() });
};

export const cloverApiGetPayment = (paymentId) => {
  return axios.get(`${apiUrl}get-payment/${paymentId}`, {
    headers: apiHeaders(),
  });
};
