<template>
  <div id="ourstory">
    <section class="about-wrap style2 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="about-img-wrap">
              <img
                :src="aboutimage1"
                alt="Image"
                class="abouut-shape-1 sm-none"
              />
              <img
                :src="aboutimage2"
                alt="Image"
                class="abouut-shape-2 sm-none"
              />
              <div class="about-bg-4 bg-f"></div>
              <div class="about-bg-5 bg-f"></div>
              <!--<div class="about-bg-6">
                <img :src="aboutbg6" alt="Image" />
              </div>-->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-content">
              <div class="content-title style2 mb-15">
                <span>
                  <img :src="sectionshape1" alt="Image" />
                  Elegant & Famous
                  <img :src="sectionshape2" alt="Image" />
                </span>
                <h2>Our Restaurant</h2>
              </div>
              <!--<p>
                In our restaurant with people who are important to you, conversations that
                bring you closer to each other and those who enjoy our dishes. Traditional
                dishes with local products of highest quality.
              </p>-->
              <p>
                Enjoy the vast variety of our food including Karahis , Curry
                dishes , BBQ, Vegi dishes , desserts, rice dishes, bread and
                drinks.
              </p>
              <p>
                We believe that excellent food and hospitality are at the center
                of all the big establishments. Our classic dishes are prepared
                with the best ingredients and under the watchful eye of our
                chef, so that your passion and standards of excellence are
                evident in every dish.
              </p>
              <!--<div class="pr-wrap style2">
                <div class="pr-item">
                  <h4>
                    <i class="flaticon-check-box-with-check-sign"></i>This Is All About
                    Caban
                  </h4>
                  <p>
                    Choose food and pay for the order in a couple of clicks online also
                    choose you current location using GPS
                  </p>
                </div>
                <div class="pr-item">
                  <h4>
                    <i class="flaticon-check-box-with-check-sign"></i>This Is Followed by
                    Quality
                  </h4>
                  <p>
                    Follow the status of your order in real time and also track the
                    delivery path until you get it.
                  </p>
                </div>
              </div>-->
              <div>
                <!--<a href="about.html" class="btn style1"
                  >Read More<i class="flaticon-right-arrow-2"></i
                ></a>-->
                <router-link to="/aboutus" class="btn style2"
                  >Read More <i class="flaticon-right-arrow-2"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ourstory",
  data() {
    return {
      aboutimage1: require("@/assets/img/about/about-shape-1.png"),
      aboutimage2: require("@/assets/img/about/about-shape-2.png"),
      //aboutbg6: require("@/assets/img/about/about-6.jpg"),
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 767px) {
  .about-wrap.style2 .about-img-wrap .about-bg-4 {
    display: none;
  }
  .about-wrap.style2 .about-img-wrap {
    width: 100% !important;
    margin: auto;
    display: inline-block;
    padding-right: 15px;
  }
  .about-wrap.style2 .about-img-wrap .about-bg-5 {
    width: 100% !important;
    /* height: 520px; */
    /* border: 6px solid #fff; */
    max-width: 398px;
    /* margin-right: 35px !important; */
    position: relative;
    left: 8px;
  }
}
@media screen and (max-width: 320px) {
   .about-wrap.style2 .about-img-wrap {
    width: 100% !important;
    margin: auto;
    display: inline-block;
    padding-right: 15px;
  }
    .about-wrap.style2 .about-img-wrap .about-bg-4 {
    display: none;
  }
}
</style>
