<template>
  <div class="home">
    <Header></Header>
    <Mainslider></Mainslider>
    <OurStory></OurStory>
    <OurServices></OurServices>
    <!--<FeaturedSlider></FeaturedSlider>-->
    <WhatWeOffer></WhatWeOffer>
    <OurApp></OurApp>
    <!--<OurBlogs></OurBlogs>-->
    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import Mainslider from "@/views/home/Mainslider.vue";
import OurStory from "@/views/home/OurStory.vue";
import OurServices from "@/views/home/OurServices.vue";
import WhatWeOffer from "@/views/home/WhatWeOffer.vue";
import OurApp from "@/views/home/OurApp.vue";
//import FeaturedSlider from '@/views/home/FeaturedSlider.vue';
import ContactUs from "@/views/home/ContactUs.vue";
//import OurBlogs from '@/views/home/OurBlogs.vue';

//import Mainslider from './layouts/Mainslider.vue';
export default {
  name: "Home",
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  components: {
    Header,
    Footer,
    Mainslider,
    OurStory,
    OurServices,
    WhatWeOffer,
    OurApp,
    //FeaturedSlider,
    //OurBlogs,
    ContactUs,
  },
};
</script>
