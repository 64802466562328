var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Header"}},[_c('header',{staticClass:"header-wrap style2"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-4 col-lg-6 col-md-6 col-4"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"assets/img/logo-black.png","alt":"Image"}})])],1),_c('div',{staticClass:"col-xl-7 col-lg-6 col-md-6 col-8"},[_c('div',{staticClass:"main-menu-wrap"},[_vm._m(1),_c('div',{attrs:{"id":"menu"}},[_c('ul',{staticClass:"main-menu list-style"},[_c('li',[_c('router-link',{class:[
                        _vm.$router.history.current.name == 'Home'
                          ? 'active'
                          : '',
                      ],attrs:{"to":"/"},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v("Home")])],1),_c('li',[_c('router-link',{class:[
                        _vm.$router.history.current.name == 'AboutUs'
                          ? 'active'
                          : '',
                      ],attrs:{"to":"/aboutus"},nativeOn:{"click":function($event){return _vm.$scrollToTop.apply(null, arguments)}}},[_vm._v("About Us")])],1),_c('li',[_c('a',{class:[
                        _vm.$router.history.current.name == 'onlineorder'
                          ? 'active'
                          : '',
                      ],attrs:{"href":"https://food.loopos.ca/?id=65d82147b3ee6659279e36da&slug=fatima-karahi-corner-svtgtv"}},[_vm._v(" Online Order ")])]),_c('li',[_c('router-link',{class:[
                        _vm.$router.history.current.name == 'menu'
                          ? 'active'
                          : '',
                      ],attrs:{"to":"/menu"}},[_vm._v(" Our Menu ")])],1),_c('li',[_c('router-link',{class:[
                        _vm.$router.history.current.name == 'Contact'
                          ? 'active'
                          : '',
                      ],attrs:{"to":"/contact"}},[_vm._v("Contact")])],1),_c('li',{staticClass:"hidden-md"},[(_vm.user && _vm.userVerify === true)?_c('router-link',{staticClass:"style1",attrs:{"to":"/myaccount"}},[_vm._v("My Account "),_c('i',{staticClass:"flaticon-right-arrow-2"})]):_vm._e()],1)])])]),_c('div',{staticClass:"mobile-bar-wrap"},[_c('router-link',{staticClass:"shopcart xl-none",attrs:{"to":"/cart"}},[_c('i',{staticClass:"las la-shopping-cart"}),_c('span',[_vm._v(_vm._s(_vm.item ? _vm.item.length : 0))])]),_vm._m(2)],1)]),_c('div',{staticClass:"col-xl-2 lg-none"},[_c('div',{staticClass:"header-bottom-right"},[(_vm.user && _vm.userVerify === true)?_c('router-link',{staticClass:"btn style1",attrs:{"to":"/myaccount"}},[_vm._v("My Account "),_c('i',{staticClass:"flaticon-right-arrow-2"})]):_vm._e()],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"close-header-top xl-none"},[_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"las la-times"})])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-xl-10 col-lg-12"},[_c('div',{staticClass:"header-top-left"},[_c('div',{staticClass:"contact-item"},[_c('i',{staticClass:"flaticon-wall-clock"}),_c('p',{staticClass:"text-white font-weight-bold"},[_vm._v(" Sun - Thu [11:00 AM — 11:00 PM] ")])]),_c('div',{staticClass:"contact-item"},[_c('p',{staticClass:"text-white font-weight-bold"},[_vm._v(" Fri - Sat [11:00 AM — 01:00 AM] ")])]),_c('div',{staticClass:"contact-item"},[_c('i',{staticClass:"flaticon-phone-call"}),_c('a',{staticClass:"text-white font-weight-bold",attrs:{"href":"tel:4034522575"}},[_vm._v("403-452-2575")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-close xl-none"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"las la-times text-dark"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-menu xl-none"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"las la-bars"})])])
}]

export { render, staticRenderFns }