var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"footer-wrap"},[_c('div',{staticClass:"footer-top bg-f footer-bg-1 pt-100 pb-70"},[_c('div',{staticClass:"overlay op-9 bg-black"}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',[_c('div',{staticClass:"footer-widget-wrap"},[_c('div',{staticClass:"footer-widget-logo col-lg-4 col-md-4"},[_c('router-link',{staticClass:"footer-logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"assets/img/logo-black.png","alt":"Image"}})]),_c('p',{staticClass:"comp-desc"},[_vm._v(" Enjoy the vast variety of our food including Karahis, Curry dishes, BBQ, Vegi dishes, desserts, rice dishes, bread and drinks. ")]),_vm._m(0),_vm._m(1)],1),_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"footer-widget col-lg-3 col-sm-4 col-md-4"},[_c('h4',{staticClass:"footer-widget-title"},[_vm._v("Quick Links")]),_c('ul',{staticClass:"footer-menu list-style"},[_c('li',[_c('router-link',{attrs:{"to":"/aboutus"}},[_vm._v("About Us")])],1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Contact Us")])],1)])]),_vm._m(3)])])])])]),_c('div',{staticClass:"footer-bottom bg-black"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(4),_c('div',{staticClass:"col-lg-3 col-md-12 col-12 order-lg-2 order-md-1 order-1"},[_c('ul',{staticClass:"footer-bottom-menu list-style"},[_c('li',[_c('router-link',{attrs:{"to":"/privacypolicy"}},[_vm._v("Privacy Policy")])],1),_vm._m(5),_vm._m(6)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-item-wrap"},[_c('div',{staticClass:"contact-item"},[_c('i',{staticClass:"flaticon-mail-1"}),_c('a',{attrs:{"href":"mailto:"}},[_c('span',{staticClass:"__cf_email__",attrs:{"data-cfemail":"1d75787171725d7e7c7f7c73337e7270"}},[_vm._v("fatimakarahi@gmail.com")])])]),_c('div',{staticClass:"contact-item"},[_c('i',{staticClass:"flaticon-phone-call"}),_c('a',{attrs:{"href":"tel:4034522575"}},[_vm._v("403-452-2575")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"social-profile style1 list-style mb-5"},[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/fatimakarahicorner/"}},[_c('i',{staticClass:"flaticon-facebook"})])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/fatimakarahicorner/"}},[_c('i',{staticClass:"flaticon-instagram"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://food.loopos.ca/?id=65d82147b3ee6659279e36da&slug=fatima-karahi-corner-svtgtv"}},[_vm._v("Online Order")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-widget col-lg-4 col-sm-4 col-md-4"},[_c('h4',{staticClass:"footer-widget-title"},[_vm._v("Opening Hours")]),_c('ul',{staticClass:"office-schedule list-style"},[_c('li',[_c('p',[_vm._v("Sunday")]),_c('p',[_vm._v("11:00 AM — 11:00 PM")])]),_c('li',[_c('p',[_vm._v("Monday")]),_c('p',[_vm._v("11:00 AM — 11:00 PM")])]),_c('li',[_c('p',[_vm._v("Tuesday")]),_c('p',[_vm._v("11:00 AM — 11:00 PM")])]),_c('li',[_c('p',[_vm._v("Wednesday")]),_c('p',[_vm._v("11:00 AM — 11:00 PM")])]),_c('li',[_c('p',[_vm._v("Thursday")]),_c('p',[_vm._v("11:00 AM — 11:00 PM")])]),_c('li',[_c('p',[_vm._v("Friday")]),_c('p',[_vm._v("11:00 AM — 01:00 AM")])]),_c('li',[_c('p',[_vm._v("Saturday")]),_c('p',[_vm._v("11:00 AM — 01:00 AM")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8 col-md-12 col-12 order-lg-1 order-md-2 order-2"},[_c('p',{staticClass:"copyright"},[_c('span',{staticClass:"las la-copyright"}),_vm._v("2023 All Rights Reserved By Fatima Karahi Corner. || Designed & Developed by "),_c('a',{attrs:{"href":"https://tngwebsolutions.ca/","target":"_blank"}},[_vm._v(" T&G Web Solutions Ltd. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Terms & Conditions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Help Center")])])
}]

export { render, staticRenderFns }