<template>
  <div id="Mainslider" style="padding: 0px; margin: 0px; overflow: hidden">
    <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true">
      <section class="hero-wrap style2 bg-f hero-slider-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="hero-content">
                <h1 style="color: #e8ae50" class="text-light">
                  Best Karahi Restaurant in Calgary
                </h1>
                <h4 style="color: #f41f31" class="font-weight-bold text-light">
                  We Bring The Best Taste in Our Dishes.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="hero-wrap style3 bg-f hero-slider2-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6"></div>
          </div>
        </div>
      </section>
      <section class="hero-wrap style4 bg-f hero-slider3-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6"></div>
          </div>
        </div>
      </section>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Mainslider",
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      //herobadge: require("../../assets/img/hero/badge.png"),
      //headerbottomimage: require("../../assets/img/hero/hero-img-2.png"),
    };
  },
};
</script>
<style>
h1.text-light {
  color: #e8ae50 !important;
}
.margin-top {
  margin-top: 34%;
  position: relative;
  margin-left: 44%;
}
.food-tablist .nav-item:nth-child(1) .nav-link i {
  font-size: 20px;
  top: 1px;
  position: relative;
}
button.nav-link {
  border-radius: 10px;
  font-weight: 800 !important;
  text-transform: uppercase;
}
.hero-slider-image {
  background-image: url("/assets/img/hero/slider1.jpg");
  height: 800px;
}
.hero-slider2-image {
  background-image: url("/assets/img/hero/slider2.jpg");
  height: 800px;
}
.hero-slider3-image {
  background-image: url("/assets/img/hero/slider3.jpg");
  height: 800px;
}
.hero-wrap.style2 {
  padding: 260px 0 50px !important;
}
.hero-wrap.style3 {
  background-image: url("/assets/img/hero/slider2.jpg");
}
.hero-wrap.style4 {
  background-image: url("/assets/img/hero/slider3.jpg");
}

@media screen and (max-width: 430px) {
  .hero-slider-image {
    height: 500px;
  }
  .hero-slider2-image {
    height: 500px;
  }
  .hero-slider3-image {
    height: 500px;
  }
}
</style>
