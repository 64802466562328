<template>
  <div id="whatweoffer">
    <section class="offer-wrap bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="offer-content ptb-100">
              <div class="content-title style2 text-left mb-15">
                <span class="">
                  <img :src="sectionshape1" alt="Image" />
                  What We Offer
                  <img :src="sectionshape1" alt="Image" />
                </span>
                <h2>Feel The Taste</h2>
              </div>
              <p class="mb-0">
                Our food is professionally cooked in a clean and healthy way
                while maintaining our special taste.
              </p>
              <p class="mb-0">
                Food is for eating, and good food is to be enjoyed.. I think
                food is, actually, very beautiful in itself.
              </p>
              <div class="promo-wrap mt-35">
                <ul class="promo-list list-style">
                  <li>
                    <span><i class="flaticon-delivery-man"></i></span> Fast
                    Delivery In Just 1 Hour
                  </li>
                  <li>
                    <span><i class="flaticon-gps"></i></span>Wide Coverage Map
                  </li>
                  <li>
                    <span><i class="flaticon-delivery-man-1"></i></span>Always
                    Served Fresh & Hot
                  </li>
                  <li>
                    <span><i class="las la-mobile-alt"></i></span>Amazing Mobile
                    App
                  </li>
                </ul>
                <!-- <div class="promo-img">
                  <img :src="fruit" alt="Image" />
                </div> -->
              </div>
            </div>
            <div class="countre-wrap mt-3">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 wex">
                  <div class="counter-card">
                    <h2>
                      <i class="flaticon-satisfaction"></i
                      ><span class="odometer" data-count="250">+</span>
                    </h2>
                    <p>
                      <span>Client Satisfaction</span> with 150+ positive
                      reviews
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-6 wex">
                  <div class="counter-card">
                    <span class="counter-icon"
                      ><i class="flaticon-psychology"></i
                    ></span>
                    <h2>
                      <i class="flaticon-chef"></i
                      ><span class="odometer" data-count="25">00</span>
                    </h2>
                    <p>
                      Our <span>Expert Chef's</span> always delivered super
                      delicious food.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="video-wrap video-bg-1 bg-f">
              <img
                :src="offsershape4"
                alt="Image"
                class="offser-shape-3"
                style="right: 0px; !important"
              />
              <!-- <a
                class="video-play circle style2"
                href="https://www.youtube.com/watch?v=ihnUg0_eS8Q"
                ><i class="flaticon-play-button-arrowhead"></i
              ></a> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "whatweoffer",
  data() {
    return {
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      fruit: require("@/assets/img/fruit.png"),
      offsershape4: require("@/assets/img/offer/offser-shape-4.png"),
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 746px) {
  .promo-img {
    display: none !important;
  }
  .col-6.wex {
    flex: 0 0 auto;
    width: 100%;
  }
}
</style>
