<template>
  <div id="Header">
    <header class="header-wrap style2">
      <div class="header-top">
        <div class="container">
          <div class="close-header-top xl-none">
            <button type="button"><i class="las la-times"></i></button>
          </div>
          <div class="row align-items-center">
            <div class="col-xl-10 col-lg-12">
              <div class="header-top-left">
                <div class="contact-item">
                  <i class="flaticon-wall-clock"></i>
                  <p class="text-white font-weight-bold">
                    Sun - Thu [11:00 AM — 11:00 PM]
                  </p>
                </div>
                <div class="contact-item">
                  <p class="text-white font-weight-bold">
                    Fri - Sat [11:00 AM — 01:00 AM]
                  </p>
                </div>
                <div class="contact-item">
                  <i class="flaticon-phone-call"></i>
                  <a href="tel:4034522575" class="text-white font-weight-bold"
                    >403-452-2575</a
                  >
                </div>
                <!--<div class="contact-item">
                  <i class="flaticon-voucher"></i>
                  <p>
                    Discount 0ff <strong>25%</strong>only for
                    <a href="index-2.html">Burger Item</a>
                    <router-link to="/">Burger Item</router-link>
                  </p>
                </div>-->
              </div>
            </div>
            <!--<div class="col-xl-2 col-lg-12">
              <div class="header-top-right">
                <div class="select-lang">
                  <div
                    class="navbar-option-item navbar-language dropdown language-option"
                  >
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="lang-name">English</span>
                    </button>
                    <div class="dropdown-menu language-dropdown-menu">
                      <a class="dropdown-item" href="#">
                        <img src="assets/img/uk.png" alt="flag" />
                        English
                      </a>
                      <a class="dropdown-item" href="#">
                        <img src="assets/img/china.png" alt="flag" />
                        简体中文
                      </a>
                      <a class="dropdown-item" href="#">
                        <img src="assets/img/uae.png" alt="flag" />
                        العربيّة
                      </a>
                    </div>
                  </div>
                </div>
                <div class="select-currency">
                  <select>
                    <option value="1">USD</option>
                    <option value="2">GBP</option>
                    <option value="3">YEN</option>
                  </select>
                </div>
              </div>
              <a href="reservation.html" class="btn style1 xl-none"
                >Make Reservation <i class="flaticon-right-arrow-2"></i>
              </a>
            </div>-->
          </div>
        </div>
      </div>
      <div class="container">
        <div class="header-bottom">
          <div class="row align-items-center">
            <div class="col-xl-4 col-lg-6 col-md-6 col-4">
              <!--<a href="index-2.html" class="logo"
                ><img src="assets/img/logo-black.png" alt="Image"
              /></a>-->
              <router-link to="/" class="logo"
                ><img src="assets/img/logo-black.png" alt="Image"
              /></router-link>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-6 col-8">
              <div class="main-menu-wrap">
                <div class="menu-close xl-none">
                  <a href="javascript:void(0)"
                    ><i class="las la-times text-dark"></i
                  ></a>
                </div>
                <div id="menu">
                  <ul class="main-menu list-style">
                    <li>
                      <router-link
                        to="/"
                        @click.native="$scrollToTop"
                        :class="[
                          $router.history.current.name == 'Home'
                            ? 'active'
                            : '',
                        ]"
                        >Home</router-link
                      >
                    </li>
                    <li>
                      <!--<a href="#">Services</a>-->
                      <router-link
                        to="/aboutus"
                        @click.native="$scrollToTop"
                        :class="[
                          $router.history.current.name == 'AboutUs'
                            ? 'active'
                            : '',
                        ]"
                        >About Us</router-link
                      >
                      <!--<ul class="sub-menu list-style">
                        <li><a href="services.html">Our Service</a></li>
                        <li>
                          <a href="service-details.html">Service Single</a>
                        </li>
                      </ul>-->
                    </li>
                    <li>
                      <a
                        href="https://food.loopos.ca/?id=65d82147b3ee6659279e36da&slug=fatima-karahi-corner-svtgtv"
                        :class="[
                          $router.history.current.name == 'onlineorder'
                            ? 'active'
                            : '',
                        ]"
                      >
                        Online Order
                      </a>
                    </li>
                    <li>
                      <router-link
                        to="/menu"
                        :class="[
                          $router.history.current.name == 'menu'
                            ? 'active'
                            : '',
                        ]"
                      >
                        Our Menu
                      </router-link>
                    </li>
                    <!--<li>
                      <router-link
                        to="/iftar-menu"
                        :class="[
                          $router.history.current.name == 'iftarmenu'
                            ? 'active'
                            : '',
                        ]"
                      >
                        Iftar Menu
                      </router-link>
                    </li>-->
                    <!--<li class="has-children">
                      <a href="#">Pages</a>
                      <ul class="sub-menu list-style">
                        <li><a href="about.html">About us</a></li>
                        <li><a href="feature.html">Feature Item</a></li>
                        <li><a href="gallery.html">Gallery</a></li>
                        <li><a href="search-location.html">Search By Location</a></li>
                        <li><a href="reservation.html">Make Reservation</a></li>
                        <li><a href="app.html">Our App</a></li>
                        <li><a href="testimonials.html">Testimonials</a></li>
                        <li><a href="faq.html">FAQ</a></li>
                        <li class="has-children">
                          <a href="#">User</a>
                          <ul class="sub-menu list-style">
                            <li>
                              <a href="login.html">Login</a>
                              <router-link to="/login">Login</router-link>
                            </li>
                            <li>
                              <a href="register.html">Register</a>
                              <router-link to="/register">Register</router-link>
                            </li>
                            <li>
                              <a href="forgot-pwd.html">Forgot Password</a>
                              <router-link to="/forgetpassword">Forgot Password</router-link>
                            </li>
                          </ul>
                        </li>
                        <li><a href="privacy-policy.html">Privacy Policy</a></li>
                        <li>
                          <a href="terms-conditions.html">Terms &amp; Conditions</a>
                        </li>
                        <li><a href="404.html">404 Error</a></li>
                      </ul>
                    </li>-->
                    <!-- <li>
                      <a href="#">Shop</a>
                      <router-link
                        to="/shop"
                        :class="[
                          $router.history.current.name == 'Shop'
                            ? 'active'
                            : '',
                        ]"
                        >Online Order</router-link
                      >
                      <a
                        href="https://order.loopos.ca/fatima-karahi-corner-ipewhq"
                        >Online Order</a
                      >
                    </li> -->
                    <!--<ul class="sub-menu list-style">
                        <li class="has-children">
                          <a href="#">Shop Layout</a>
                          <ul class="sub-menu list-style">
                            <li>
                              <a href="shop-left-sidebar.html">Shop Left Sidebar</a>
                            </li>
                            <li>
                              <a href="shop-right-sidebar.html">Shop Right Sidebar</a>
                            </li>
                            <li>
                              <a href="shop-no-sidebar.html">Shop Grid</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="shop-details.html">Shop Single</a>
                        </li>
                        <li>
                          <a href="cart.html">Cart</a>
                        </li>
                        <li>
                          <a href="wishlist.html">Wishlist</a>
                        </li>
                        <li>
                          <a href="checkout.html">Checkout</a>
                        </li>
                      </ul>
                    </li>-->
                    <!--<li class="has-children">
                      <a href="#">Blog</a>
                      <ul class="sub-menu list-style">
                        <li class="has-children">
                          <a href="#">Blog Layout</a>
                          <ul class="sub-menu list-style">
                            <li><a href="blog-no-sidebar.html">Blog Grid </a></li>
                            <li>
                              <a href="blog-left-sidebar.html">Blog Left Sidebar</a>
                            </li>
                            <li>
                              <a href="blog-right-sidebar.html">Blog Right Sidebar</a>
                            </li>
                          </ul>
                        </li>
                        <li class="has-children">
                          <a href="#">Blog Single</a>
                          <ul class="sub-menu list-style">
                            <li>
                              <a href="blog-details-no-sidebar.html"
                                >Blog Single No Sidebar</a
                              >
                            </li>
                            <li>
                              <a href="blog-details-left-sidebar.html"
                                >Blog Single Left Sidebar</a
                              >
                            </li>
                            <li>
                              <a href="blog-details-right-sidebar.html"
                                >Blog Single Right Sidebar</a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>-->
                    <!--<a href="contact.html">Contact</a>-->
                    <li>
                      <router-link
                        to="/contact"
                        :class="[
                          $router.history.current.name == 'Contact'
                            ? 'active'
                            : '',
                        ]"
                        >Contact</router-link
                      >
                    </li>

                    <li class="hidden-md">
                      <!-- <router-link
                        to="/login"
                        class="style1"
                        v-if="!user || (user && userVerify == false)"
                        >Login <i class="flaticon-right-arrow-2"></i
                      ></router-link> -->
                      <router-link
                        to="/myaccount"
                        class="style1"
                        v-if="user && userVerify === true"
                        >My Account <i class="flaticon-right-arrow-2"></i
                      ></router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mobile-bar-wrap">
                <!--<a href="cart.html" class="shopcart xl-none">
                  <i class="las la-shopping-cart"></i>
                  <span>1</span>
                </a>-->
                <router-link to="/cart" class="shopcart xl-none">
                  <i class="las la-shopping-cart"></i>
                  <span>{{ item ? item.length : 0 }}</span>
                </router-link>
                <!-- <div class="mobile-top-bar xl-none">
                  <span></span>
                  <span></span>
                  <span></span>
                </div> -->
                <div class="mobile-menu xl-none">
                  <a href="javascript:void(0)"><i class="las la-bars"></i></a>
                </div>
              </div>
            </div>
            <div class="col-xl-2 lg-none">
              <div class="header-bottom-right">
                <!--<a href="cart.html" class="shopcart">
                  <i class="las la-shopping-cart"></i>
                  <span>1</span>
                </a>-->
                <!-- <router-link to="/cart" class="shopcart"
                  ><i class="las la-shopping-cart"></i
                  ><span>{{ item ? item.length : 0 }}</span></router-link
                > -->
                <!--<a href="reservation.html" class="btn style1"
                  >Make Reservation <i class="flaticon-right-arrow-2"></i>
                </a>-->
                <!-- <router-link
                  to="/login"
                  class="btn style1"
                  v-if="!user || (user && userVerify == false)"
                  >Login <i class="flaticon-right-arrow-2"></i
                ></router-link> -->
                <router-link
                  to="/myaccount"
                  class="btn style1"
                  v-if="user && userVerify === true"
                  >My Account <i class="flaticon-right-arrow-2"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { getLocalStorage } from "@/store/service";
import { getRestaurantInfo } from "@/store/api";

export default {
  props: ["newCart"],
  data() {
    return {
      user: [],
      item: [],
      userVerify: "",
      open: 0,
    };
  },
  watch: {
    newCart() {
      if (this.newCart) {
        this.item = this.newCart;
      }
    },
    cartshow() {
      this.slideMinicart(this.cartshow ? "show" : "hide");
    },
  },
  methods: {
    getResInfo() {
      getRestaurantInfo().then((res) => {
        console.log(res.data);
        this.open = res.data.open;
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    showItem() {
      this.user = getLocalStorage("userData");
      this.userVerify = getLocalStorage("userDataVerify");
      this.item = getLocalStorage("cart");
    },
  },
  name: "Header",
  mounted() {
    this.scrollToTop();
    this.getResInfo();
    const externalScript = document.createElement("script");
    externalScript.setAttribute("src", "assets/js/jquery.min.js");
    externalScript.setAttribute("type", "text/javascript");
    document.head.appendChild(externalScript);
    const bootstrapScript = document.createElement("script");
    bootstrapScript.setAttribute("src", "assets/js/bootstrap.min.js");
    bootstrapScript.setAttribute("type", "text/javascript");
    document.head.appendChild(bootstrapScript);
    const formValidator = document.createElement("script");
    formValidator.setAttribute("src", "assets/js/form-validator.min.js");
    formValidator.setAttribute("type", "text/javascript");
    document.head.appendChild(formValidator);
    const contactScript = document.createElement("script");
    contactScript.setAttribute("src", "assets/js/contact-form-script.js");
    contactScript.setAttribute("type", "text/javascript");
    document.head.appendChild(contactScript);
    const aosScript = document.createElement("script");
    aosScript.setAttribute("src", "assets/js/aos.js");
    aosScript.setAttribute("type", "text/javascript");
    document.head.appendChild(aosScript);
    const swiperScript = document.createElement("script");
    swiperScript.setAttribute("src", "assets/js/swiper-min.js");
    swiperScript.setAttribute("type", "text/javascript");
    document.head.appendChild(swiperScript);
    const jqueryMagnific = document.createElement("script");
    jqueryMagnific.setAttribute("src", "assets/js/jquery-magnific-popup.js");
    jqueryMagnific.setAttribute("type", "text/javascript");
    document.head.appendChild(jqueryMagnific);
    const Appear = document.createElement("script");
    Appear.setAttribute("src", "assets/js/jquery.appear.js");
    Appear.setAttribute("type", "text/javascript");
    document.head.appendChild(Appear);
    const odometre = document.createElement("script");
    odometre.setAttribute("src", "assets/js/odometre.min.js");
    odometre.setAttribute("type", "text/javascript");
    document.head.appendChild(odometre);
    const main = document.createElement("script");
    main.setAttribute("src", "assets/js/main.js");
    main.setAttribute("type", "text/javascript");
    document.head.appendChild(main);
    // this.getSetting();
    // this.getCalc();
    this.showItem();
  },
};
</script>
<style scoped>
@media only screen and (max-width: 1199px) {
  .header-top {
    background-color: #000000;
  }
  .la-times {
    color: #ffff;
  }
}
.hidden-md {
  display: none !important;
}
@media only screen and (max-width: 756px) {
  .hidden-md {
    display: block !important;
  }
}
</style>
