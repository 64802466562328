<template>
  <div>
    <footer class="footer-wrap">
      <div class="footer-top bg-f footer-bg-1 pt-100 pb-70">
        <div class="overlay op-9 bg-black"></div>
        <div class="container">
          <div class="row">
            <div>
              <div class="footer-widget-wrap">
                <div class="footer-widget-logo col-lg-4 col-md-4">
                  <!--<a href="index-2.html" class="footer-logo">
                    <img src="assets/img/logo-white.png" alt="Image" />
                  </a>-->
                  <router-link to="/" class="footer-logo"
                    ><img src="assets/img/logo-black.png" alt="Image"
                  /></router-link>
                  <p class="comp-desc">
                    Enjoy the vast variety of our food including Karahis, Curry
                    dishes, BBQ, Vegi dishes, desserts, rice dishes, bread and
                    drinks.
                  </p>
                  <div class="contact-item-wrap">
                    <div class="contact-item">
                      <i class="flaticon-mail-1"></i>
                      <a href="mailto:"
                        ><span
                          class="__cf_email__"
                          data-cfemail="1d75787171725d7e7c7f7c73337e7270"
                          >fatimakarahi@gmail.com</span
                        ></a
                      >
                    </div>
                    <div class="contact-item">
                      <i class="flaticon-phone-call"></i>
                      <a href="tel:4034522575">403-452-2575</a>
                    </div>
                  </div>
                  <ul class="social-profile style1 list-style mb-5">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/fatimakarahicorner/"
                        ><i class="flaticon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/fatimakarahicorner/"
                      >
                        <i class="flaticon-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-1"></div>
                <div class="footer-widget col-lg-3 col-sm-4 col-md-4">
                  <h4 class="footer-widget-title">Quick Links</h4>
                  <ul class="footer-menu list-style">
                    <!--<li><a href="about.html">About Us</a></li>
                    <li><a href="menus.html">Menus</a></li>
                    <li><a href="shop-left-sidebar.html">Shop</a></li>
                    <li><a href="wishlist.html">Wishlist</a></li>
                    <li><a href="contact.html">Contact Us</a></li>
                    <li><a href="contact.html">Career</a></li>-->
                    <li><router-link to="/aboutus">About Us</router-link></li>
                    <!-- <li><router-link to="/shop">Shop</router-link></li> -->
                    <li>
                      <a
                        href="https://food.loopos.ca/?id=65d82147b3ee6659279e36da&slug=fatima-karahi-corner-svtgtv"
                        >Online Order</a
                      >
                    </li>
                    <li><router-link to="/contact">Contact Us</router-link></li>
                    <!-- <li><router-link to="/login">Login</router-link></li>
                    <li><router-link to="/register">Register</router-link></li> -->
                  </ul>
                </div>
                <div class="footer-widget col-lg-4 col-sm-4 col-md-4">
                  <h4 class="footer-widget-title">Opening Hours</h4>
                  <ul class="office-schedule list-style">
                    <li>
                      <p>Sunday</p>
                      <p>11:00 AM — 11:00 PM</p>
                    </li>
                    <li>
                      <p>Monday</p>
                      <p>11:00 AM — 11:00 PM</p>
                    </li>
                    <li>
                      <p>Tuesday</p>
                      <p>11:00 AM — 11:00 PM</p>
                    </li>
                    <li>
                      <p>Wednesday</p>
                      <p>11:00 AM — 11:00 PM</p>
                    </li>
                    <li>
                      <p>Thursday</p>
                      <p>11:00 AM — 11:00 PM</p>
                    </li>
                    <li>
                      <p>Friday</p>
                      <p>11:00 AM — 01:00 AM</p>
                    </li>
                    <li>
                      <p>Saturday</p>
                      <p>11:00 AM — 01:00 AM</p>
                    </li>
                  </ul>
                </div>
                <!--<div class="footer-widget">
                  <h4 class="footer-widget-title">Instagram Photo</h4>
                  <div class="instagram-img-wrap">
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-1.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-2.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-3.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-4.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-5.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-6.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-7.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-8.jpg" alt="Image" />
                    </a>
                    <a href="https://instagram.com/" target="_blank" class="insta-box">
                      <img src="assets/img/instagram/instagram-9.jpg" alt="Image" />
                    </a>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom bg-black">
        <div class="container">
          <div class="row align-items-center">
            <div
              class="col-lg-8 col-md-12 col-12 order-lg-1 order-md-2 order-2"
            >
              <p class="copyright">
                <span class="las la-copyright"></span>2023 All Rights Reserved
                By Fatima Karahi Corner. || Designed & Developed by
                <a href="https://tngwebsolutions.ca/" target="_blank">
                  T&G Web Solutions Ltd.
                </a>
              </p>
            </div>
            <div
              class="col-lg-3 col-md-12 col-12 order-lg-2 order-md-1 order-1"
            >
              <ul class="footer-bottom-menu list-style">
                <li>
                  <router-link to="/privacypolicy">Privacy Policy</router-link>
                </li>
                <li><a href="#">Terms &amp; Conditions</a></li>
                <li><a href="#">Help Center</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer-logo {
  max-width: 70%;
  height: auto;
}
ul.footer-bottom-menu.list-style li a {
  font-size: 12px !important;
}
p.copyright {
  font-size: 14px;
}
@media only screen and (max-width: 430px) {
  .col {
    flex: auto !important;
  }

  .mt {
    margin-top: 50px;
  }
  .footer-logo {
    max-width: 50%;
    height: auto;
  }
  .footer-widget-wrap {
    padding: 17px;
  }
}
</style>
