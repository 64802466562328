<template>
  <div id="ourapp">
    <section class="app-wrap style2 ptb-100 bg-f app-bg-1">
      <div class="overlay op-9 bg-wild-sand"></div>
      <img :src="shape1" alt="Image" class="app-shape-1 sm-none" />
      <img :src="shape2" alt="Image" class="app-shape-2 sm-none" />
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="app-img">
              <img :src="shape5" alt="Image" class="app-img-shape-1" />
              <img :src="shape6" alt="Iamge" class="app-img-shape-2" style="right: 0px; !important" />
              <img src="assets/img/app/app-showcase.png" alt="Image" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="app-content">
              <div class="content-title style2 text-left mb-20">
                <span>
                  <img :src="sectionshape1" alt="Image" />
                  Our App
                  <img :src="sectionshape2" alt="Image" />
                </span>
                <h2>Order With Our Application</h2>
              </div>
              <div class="pr-wrap style2">
                <div class="pr-item">
                  <h4>
                    <i class="las la-check-square"></i>Order And Pay In A Few Minutes
                  </h4>
                  <p>
                    Сhoose food and pay for the order in a couple of clicks online also
                    choose you current location using GPS
                  </p>
                </div>
                <div class="pr-item">
                  <h4><i class="las la-check-square"></i>Check Delivery Status</h4>
                  <p>
                    Follow the status of your order in real time and also track the
                    delivery path until you get it.
                  </p>
                </div>
              </div>
              <div class="download-app-btn mt-30">
                <a href="app.html"
                  ><img src="assets/img/app/google-playstore.png" alt="Image"
                /></a>
                <a href="app.html"
                  ><img src="assets/img/app/apple-playstore.png" alt="Image"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ourapp",
  data() {
    return {
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      shape5: require("@/assets/img/app/shape-5.png"),
      shape6: require("@/assets/img/app/shape-6.png"),
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
    };
  },
};
</script>
