<template>
  <div id="ourservices">
    <section class="service-wrap bg-wild-sand pt-50 pb-75">
      <div class="container">
        <div class="section-title style1 text-center mb-40">
          <span>
            <img :src="sectionshape1" alt="Image" />
            Our Services
            <img :src="sectionshape2" alt="Image" />
          </span>
          <h2>What We Offer</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="service-card style2">
              <router-link to="/shop">
                <div>
                  <img :src="breakfast" alt="Image" />
                  <!--<img class="service-card-shape-1" :src="servicecard1" alt="Image" />
                <img class="service-card-shape-2" :src="servicecard2" alt="Image" />-->
                </div>
                <div class="service-info">
                  <h3 class="service-title">
                    <router-link to="/shop">Fatima Special Karahi</router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-card style2">
              <router-link to="/shop">
                <div>
                  <img :src="lunch" alt="Image" />
                  <!--<img class="service-card-shape-1" :src="servicecard1" alt="Image" />
                <img class="service-card-shape-2" :src="servicecard2" alt="Image" />-->
                </div>
                <div class="service-info">
                  <h3 class="service-title">
                    <router-link to="/shop">BBQ Dishes</router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-card style2">
              <router-link to="/shop">
                <div>
                  <img :src="dinner" alt="Image" />
                  <!--<img class="service-card-shape-1" :src="servicecard1" alt="Image" />
                <img class="service-card-shape-2" :src="servicecard2" alt="Image" />-->
                </div>
                <div class="service-info">
                  <h3 class="service-title">
                    <router-link to="/shop">Curry Dishes</router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="service-card style2">
              <router-link to="/shop">
                <div>
                  <img :src="custom" alt="Image" />
                  <!--<img class="service-card-shape-1" :src="servicecard2" alt="Image" />
                <img class="service-card-shape-2" :src="servicecard2" alt="Image" />-->
                </div>
                <div class="service-info">
                  <h3 class="service-title">
                    <router-link to="/shop">Rice Dishes</router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ourservices",
  data() {
    return {
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      breakfast: require("@/assets/img/service/breakfast.png"),
      servicecard1: require("@/assets/img/service/service-card-shape-1.png"),
      servicecard2: require("@/assets/img/service/service-card-shape-2.png"),
      lunch: require("@/assets/img/service/lunch.png"),
      dinner: require("@/assets/img/service/dinner.png"),
      custom: require("@/assets/img/service/custom.png"),
    };
  },
};
</script>
<style scoped>
.service-info {
  margin-top: 21px;
}
</style>
