import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { resendVerifyOtp } from "../store/api";
import { getLocalStorage } from "../store/service";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/home2",
    name: "home2",
    component: () => import("@/views/Home2.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/auth/Register.vue"),
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: () => import("@/views/auth/ForgetPassword.vue"),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import("@/views/about/AboutUs.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("@/views/shop/Shop.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("@/views/shop/Cart.vue"),
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    component: () => import("@/views/shop/Wishlist.vue"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("@/views/shop/Checkout.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/contact/Contact.vue"),
  },
  {
    path: "/myaccount",
    name: "myaccount",
    component: () => import("@/views/user/MyAccount.vue"),
  },
  {
    path: "/myorders",
    name: "myorders",
    component: () => import("@/views/user/MyOrders.vue"),
  },
  {
    path: "/orderdetails/:id/:uniqueId",
    name: "orderdetails",
    component: () => import("@/views/user/OrderDetails.vue"),
  },
  {
    path: "/otpverify",
    name: "OtpVerify",
    component: () => import("@/views/user/OtpVerify.vue"),
  },
  {
    path: "/manageaddress",
    name: "manageaddress",
    component: () => import("@/views/user/ManageAddress.vue"),
  },
  {
    path: "/addaddress",
    name: "addaddress",
    component: () => import("@/views/user/AddAddress.vue"),
  },
  {
    path: "/mywallet",
    name: "mywallet",
    component: () => import("@/views/user/MyWallet.vue"),
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("@/views/user/Otp.vue"),
  },
  {
    path: "/loginviaotp",
    name: "loginviaotp",
    component: () => import("@/views/user/LoginViaOtp.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/menu/Menu.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/views/auth/ChangePassword.vue"),
  },
  {
    path: "/shopdetails/:id",
    name: "shopdetails",
    component: () => import("@/views/shop/ShopDetails.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("@/views/privacy/PrivacyPolicy.vue"),
  },
  {
    path: "/table-login/:table",
    name: "tableLogin",
    component: () => import("@/views/shop/Table_login.vue"),
  },
  {
    path: "/success/:paymentId/:security",
    name: "success",
    component: () => import("@/views/Success.vue"),
  },
  {
    path: "/failure",
    name: "failure",
    component: () => import("@/views/Failure.vue"),
  },
  {
    path: "/cancel",
    name: "cancel",
    component: () => import("@/views/Cancel.vue"),
  },
  {
    path: "/cardpayment",
    name: "cardpayment",
    component: () => import("@/views/CardPayment.vue"),
  },
  {
    path: "/iftar-menu",
    name: "iftarmenu",
    component: () => import("@/views/menu/IftarMenu.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const userData = getLocalStorage("userData");
  const userDataVerify = getLocalStorage("userDataVerify")
    ? getLocalStorage("userDataVerify")
    : false;

  if (userData) {
    if (
      to.path === "/login" ||
      to.path === "/register" ||
      to.path === "" ||
      to.path === "/forget" ||
      to.path === "/reset-password"
    ) {
      return next("/");
    } else if (userData.verified_at === null || userDataVerify === false) {
      if (
        to.path !== "/otpverify" ||
        to.path === "/myaccount" ||
        to.path === "/checkout" ||
        to.path === "/editcart" ||
        to.path === "/ManageAddress" ||
        to.path === "/addmanageaddress" ||
        to.path === "/myorder" ||
        to.path === "/wallet"
      ) {
        // console.log(userData.verified_at)
        const form = {
          email: userData.email,
          phone: userData.phone,
        };
        resendVerifyOtp(form).then((res) => {
          console.log(res.data);
        });
        return next({
          path: "/otpverify",
          query: { redirect: "email" },
        });
      } else {
        return next();
      }
    }
  } else if (!userData) {
    if (
      to.path === "/myaccount" ||
      to.path === "/checkout" ||
      to.path === "/editcart" ||
      to.path === "/ManageAddress" ||
      to.path === "/addmanageaddress" ||
      to.path === "/myorder" ||
      to.path === "/wallet"
    ) {
      return next("/login");
    }
  } else {
    return next();
  }
  return next();
});
export default router;
